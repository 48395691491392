import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import Admin from "../pages/Admin";
import AdminLogin from "../pages/AdminLogin.jsx";

const Routers = () => {
  const isAuthenticated = () => {
    // Replace this logic with your actual authentication check
    // For example, check if 'admin_token' exists in session storage
    return !!sessionStorage.getItem('admin_token');
  };

  const ProtectedRoute = ({ element }) => {
    return isAuthenticated() ? element : <Navigate to="/adminlogin" />;
  };

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/admin" element={<ProtectedRoute element={<Admin />} />} />
      <Route path="/adminlogin" element={<AdminLogin />} />
    </Routes>
  );
};

export default Routers;
