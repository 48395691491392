import React, { useState, useEffect } from 'react';
import "../styles/edit-form.css"; // Import the CSS file

const EditCarForm = ({ car, onUpdateCar, handleDeleteCar }) => {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    model: '',
    price: '',
    automatic: '',
    image: ''
  });

  useEffect(() => {
    if (car) {
      setFormData({
        id: car.id || '',
        name: car.name || '',
        model: car.model || '',
        price: car.price || '',
        automatic: car.automatic || '',
        image: car.image || ''
      });
    }
  }, [car]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const putData = new FormData();
      putData.append('name', formData.name);
      putData.append('model', formData.model);
      putData.append('price', formData.price);
      putData.append('automatic', formData.automatic);

      await onUpdateCar({ id: formData.id, formData: putData });
      
    } catch (error) {
      console.error('Update car error:', error);
    }
  };

  if (!formData.id) {
    return null;
  }

  return (
    <form className="edit-car-form" onSubmit={handleSubmit}>
      <img src={`https://vdrive.in/` + formData.image} alt={formData.name} className="car-image" />
      <input
        type="text"
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
        className="form-input"
        required
      />
      <input
        type="text"
        name="model"
        placeholder="Model"
        value={formData.model}
        onChange={handleChange}
        className="form-input"
        required
      />
      <input
        type="number"
        name="price"
        placeholder="Price"
        value={formData.price}
        onChange={handleChange}
        className="form-input"
        required
      />
      <select
        name="automatic"
        value={formData.automatic}
        onChange={handleChange}
        className="form-input"
        required
      >
        <option value="">Select Transmission</option>
        <option value="Manual">Manual</option>
        <option value="Automatic">Automatic</option>
      </select>
      <button type="submit" className="form-submit-button">Update Car</button>
      <button
        type="button"
        onClick={() => handleDeleteCar(car.id)}
        className="form-delete-button"
      >
        Delete
      </button>
    </form>
  );
};

export default EditCarForm;
